a {
    /*color: #603084;*/
    text-decoration: none;
}

.date-range-input {
    padding: 12px;
    border-radius: 4px;
    border-width: 1px;
    width: 160px;
}

.active-menu .icon{
    color: white;
}


